import React, { useContext, useEffect } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import navbarimage from "assets/img/layout/Navbar.png";
import { BsArrowBarUp } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill, RiUser3Fill } from "react-icons/ri";
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import avatar from "assets/img/avatars/avatar4.png";
import { useDisclosure } from "@chakra-ui/hooks";

import AuthContext from "storage/AuthContext";
import { signOutHelper } from "utils/auth.helper";
import PasswordUpdateModal from "components/modal/PasswordUpdateModal";
import CompanyInfoUpdateModal from "components/modal/CompanyInfoUpdateModal";

import NotificationsIcon from "@mui/icons-material/Notifications";
import ForumIcon from "@mui/icons-material/Forum";
import { DarkMode } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import WavingHandIcon from "@mui/icons-material/WavingHand";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  brandPath?: string;
  secondary?: boolean | string;
}) => {
  const { onOpenSidenav, brandText, brandPath } = props;
  const [darkmode, setDarkmode] = React.useState(() => {
    // Check user preference on initial load

    // TODO: this needs to be fixed it doesn't work as expected
    // const userPrefersDark = window.localStorage.getItem("theme") === "dark";
    // const systemPrefersDark =
    //   window.matchMedia &&
    //   window.matchMedia("(prefers-color-scheme: dark)").matches;
    // return userPrefersDark || systemPrefersDark;

    return false;
  });

  useEffect(() => {
    if (darkmode) {
      document.body.classList.add("dark");
    }
  }, [darkmode]);

  const { currentUser, logout } = useContext(AuthContext);

  const setDarkModeHandler = () => {
    if (darkmode) {
      document.body.classList.remove("dark");
      localStorage.setItem("theme", "light");
      setDarkmode(false);
    } else {
      document.body.classList.add("dark");
      localStorage.setItem("theme", "dark");
      setDarkmode(true);
    }
  };

  const logoutHandler = async (event: any) => {
    event.preventDefault();
    await signOutHelper();
    logout();
  };

  const {
    isOpen: isOpenPasswordModal,
    onOpen: onOpenPasswordModal,
    onClose: onClosePasswordModal,
  } = useDisclosure();
  const {
    isOpen: isOpenInfoModal,
    onOpen: onOpenInfoModal,
    onClose: onCloseInfoModal,
  } = useDisclosure();

  return (
    <nav className="sticky top-4 z-20 mb-[40px] flex flex-row flex-wrap items-center justify-between rounded-xl bg-lightPrimary/55 p-2 backdrop-blur-xl dark:border-b-[#0D0E121F] dark:bg-[#0d0e12b3]">
      <div className="ml-[6px]">
        <div className="h-6 w-[280px] pt-1 text-[#444746] dark:text-[#636674] sm:w-[500px]">
          <a className="text-sm font-normal  hover:underline " href=" ">
            Pages
            <span className="mx-1 text-sm  hover:text-navy-700"> / </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-[##444746] hover:underline "
            to="#"
          >
            {brandPath ? `events / ${brandPath}` : brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize">
          <Link
            to="#"
            className="font-bold capitalize text-[#041E49] dark:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>
      <div>
        {/* <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div> */}

        <span
          className="flex cursor-pointer text-xl text-gray-600 xl:hidden"
          onClick={onOpenSidenav}
        >
          <MenuIcon className="h-[24px] w-[24px] text-[#ABABAB] hover-opacity-80" />
        </span>
        {/* start Notification */}
        {/* <Dropdown
          button={
            <p className="cursor-pointer">
              <NotificationsIcon className="h-[24px] w-[24px] text-[#ABABAB] hover-opacity-80" />
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700  dark:shadow-none sm:w-[460px]">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-navy-700 ">
                  Notification
                </p>
                <p className="text-sm font-bold text-navy-700">Mark all read</p>
              </div>

              <button className="flex w-full items-center">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 ">
                    New Update: Horizon UI Dashboard PRO
                  </p>
                  <p className="font-base text-left text-xs text-gray-900">
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>

              <button className="flex w-full items-center">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 ">
                    New Update: Horizon UI Dashboard PRO
                  </p>
                  <p className="font-base text-left text-xs text-gray-900 ">
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>
            </div>
          }
          classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
        /> */}
        {/* <span className="flex cursor-pointer text-xl text-gray-600">
          <ForumIcon className="h-[24px] w-[24px] text-[#ABABAB] hover-opacity-80" />
        </span> */}

        {/* <div
          className="cursor-pointer text-gray-600"
          onClick={setDarkModeHandler}
        >
          {darkmode ? (
            <RiSunFill className="h-[24px] w-[24px] text-[#ABABAB] hover-opacity-80 " />
          ) : (
            <DarkMode className="h-[24px] w-[24px] text-[#ABABAB] hover-opacity-80 " />
          )}
        </div> */}
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <>
              {/* <img
                className="h-10 w-10 rounded-full"
                src={currentUser.imgUrl || avatar}
                alt="Elon Musk"
              /> */}
              <p
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#1b6ef3",
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {currentUser?.firstname[0] ?? "-"}{currentUser?.lastname[0] ?? "-"}
              </p>
            </>
          }
          children={
            <div className="flex w-56 pb-4 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:bg-[#15171C] dark:text-white">
              <div className="ml-4 mt-3">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-[#041E49] dark:text-white ">
                    Hey, {currentUser.firstname}
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="ml-4 mt-3 flex flex-col items-start">
                <Link
                  to="/admin/profile"
                  className="text-sm font-medium text-[#808290] text-gray-800 hover-opacity-80 dark:text-white hover:dark:text-white"
                >
                  Profile
                </Link>
                {/* <button
                  onClick={onOpenPasswordModal}
                  className="mt-3 text-sm font-medium text-[#808290] text-gray-800 hover-opacity-80 dark:text-white hover:dark:text-white"
                >
                  Update Password
                </button>
                <button
                  onClick={onOpenInfoModal}
                  className="mt-3 text-sm font-medium text-[#808290] text-gray-800 hover-opacity-80 dark:text-white hover:dark:text-white"
                >
                  Update Info
                </button> */}
                <button
                  onClick={logoutHandler}
                  className="mt-3 text-sm font-medium text-red-500 hover-opacity-80"
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
      {isOpenPasswordModal && (
        <PasswordUpdateModal
          isOpen={isOpenPasswordModal}
          onClose={onClosePasswordModal}
        />
      )}
      {isOpenInfoModal && (
        <CompanyInfoUpdateModal
          isOpen={isOpenInfoModal}
          onClose={onCloseInfoModal}
        />
      )}
    </nav>
  );
};

export default Navbar;
