import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "@firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyC5qXVgsTurdTlFWc5TfbIt11iVpkNIFJg",
  authDomain: "d4e-dev.firebaseapp.com",
  projectId: "d4e-dev",
  storageBucket: "d4e-dev.appspot.com",
  databaseURL: "https://d4e-dev-default-rtdb.europe-west1.firebasedatabase.app",
  messagingSenderId: "680198895840",
  appId: "1:680198895840:web:8f7df75171fddd945b3885",
  measurementId: "G-YL5L3J2KQ8",
};

export const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(firebaseApp);

export const firestore = getFirestore(firebaseApp);

export const db = getDatabase(firebaseApp);

export const storage = getStorage(firebaseApp);
