import React, { useCallback, useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "utils/firebase";
import { fethImageUrl } from "utils/storage.helper";
import { User } from "../types/types";
import { getUserDataHelper } from "utils/users.helper";
import Loader from "components/animations/Loader";

const AuthContext = React.createContext({
  isLoggedIn: false,
  forcePasswordReset: false,
  login: (idToken: string, userRole: string) => {},
  logout: () => {},
  currentUser: {} as User,
  token: "",
  isLoading: true,
});

interface AuthContextProviderProps {
  children: React.ReactNode;
}

let initialUser = {} as User;

export const AuthContextProvider: React.FC<AuthContextProviderProps> = (
  props: any
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState<User>(initialUser);
  const [forcePasswordReset, setForcePasswordReset] = useState(false);
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  const login = useCallback(async (idToken: string, user: any) => {
    setIsLoggedIn(true);
    const role = JSON.parse(user.reloadUserInfo.customAttributes).role;

    let userInfo: User;
    let imgUrl: string;

    try {
      const userDocRef = doc(firestore, "users", user.uid);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        userInfo = docSnap.data() as User;
        setForcePasswordReset(docSnap.data().forcePasswordReset);
        imgUrl = await fethImageUrl(
          "uploads/profile_picture/thumbs/" + user.uid + "_128x128"
        );
      } else {
        console.log("No such user!");
      }
    } catch (error) {
      console.log(error);
    }
    setCurrentUser((prev) => {
      let updatedUser = { ...prev };

      updatedUser.uid = user.uid;
      updatedUser.role = userInfo?.role || "user";
      updatedUser.email = userInfo?.email;
      updatedUser.firstname = userInfo?.firstname;
      updatedUser.lastname = userInfo?.lastname;
      updatedUser.number = userInfo?.number;
      updatedUser.companyId = userInfo?.companyId;
      if (userInfo?.role === "company") updatedUser.companyId = user?.uid;
      updatedUser.companyName = userInfo?.companyName;
      updatedUser.imgUrl = imgUrl;

      console.log(updatedUser);
      return updatedUser;
    });
    setIsLoading(false);
  }, []);

  const authStateChangeHandler = useCallback(
    async (user: any) => {
      if (user) {
        try {
          // User is signed in - get ID token
          const idToken = await user.getIdToken();

          if (idToken) {
            login(idToken, user);
            setToken(idToken);
          }
        } catch (error) {
          console.error("Error getting ID token:", error);
        }
      } else {
        // User is signed out
        setIsLoggedIn(false);
        setIsLoading(false);
        console.log("User is signed out");
      }
    },
    [login]
  );

  useEffect(() => {
    // Set up Firebase auth listener
    const unsubscribe = auth.onAuthStateChanged(authStateChangeHandler);

    // Cleanup auth listener on component unmount
    return () => unsubscribe();
  }, [authStateChangeHandler]);

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     setIsLoading(false); // Mark the authentication process as complete when logged in
  //   }
  // }, [isLoggedIn]);

  if (isLoading) {
    // Render a loading indicator or some interim UI while checking the authentication status
    return <Loader />;
  }

  // return <Loader />;

  const logout = () => {
    setIsLoggedIn(false);
    setForcePasswordReset(false);
    // navigate("/auth/sign-in");
  };

  const value = {
    isLoggedIn,
    token,
    login,
    logout,
    forcePasswordReset,
    currentUser,
    isLoading,
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export default AuthContext;
