import axios from "axios";
import { auth } from "./firebase";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";

export const signInWithGoogleHelper = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    return { hasError: false };
  } catch (error: any) {
    console.error(error.message);
    throw new Error(error.message);
  }
};

// export function signInWithGoogleHelper() {
//   const provider = new GoogleAuthProvider();

//   signInWithPopup(auth, provider)
//     .then((result) => {
//       // The signed-in user info.
//       const user = result.user;

//       // If you need to send data to your backend
//       // const idToken = result.credential.idToken;
//       return {haserror: false}

//     })
//     .catch((error) => {
//       // Handle Errors here.
//       console.error(error.message);
//       return {hasError: true, errorMessage: error.message}
//     });
// }

export const signInWithEmailAndPasswordHelper = async (
  email: string,
  password: string
) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    return { hasError: false };
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;

    throw new Error(errorMessage);
  }
};

// export function signInWithEmailAndPasswordHelper(email:string, password: string) {
//     signInWithEmailAndPassword(auth, email, password)
//       .then((userCredential) => {
//         // Signed in

//         const user = userCredential.user;
//         console.log(user);
//         return false
//       })
//       .catch((error) => {
//         const errorCode = error.code;
//         const errorMessage = error.message;
//         console.log(errorCode, errorMessage);
//         return true
//       });
// }

export const signOutHelper = async () => {
  try {
    await signOut(auth);
  } catch (err) {
    console.log(err);
  }
};

export const createUser = async (
  email: string,
  password: string,
  role: string,
  uid: string,
  token: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user/create`,
      {
        email,
        password,
        role,
        companyId: role === "company_manager" ? uid : null,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return { hasError: false, errorMessage: "" };
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
};

export const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset email sent successfully!");
    // Handle success - Password reset email sent
  } catch (error) {
    console.error("Error sending reset email:", error);
    // Handle error sending reset email
  }
};
