import React from "react";

// Admin components Imports
// import MainDashboard from "views/admin/default";

// Auth Imports
import SignIn from "views/auth/SignIn";
import SignUp from "views/auth/SignUp";

//icons
import BusinessIcon from "@mui/icons-material/Business";
import HomeIcon from "@mui/icons-material/Home";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ForumIcon from "@mui/icons-material/Forum";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import TodayIcon from "@mui/icons-material/Today";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const MainDashboard = React.lazy(() => import("views/admin/default"));
const Profile = React.lazy(() => import("views/admin/profile"));
const Users = React.lazy(() => import("views/admin/users"));
const Contact = React.lazy(() => import("views/admin/contact"));
const Companies = React.lazy(() => import("views/admin/companies"));
const Events = React.lazy(() => import("views/admin/events"));
const CompanyUsers = React.lazy(() => import("views/admin/companyUsers"));
const EventDetails = React.lazy(
  () => import("views/admin/events/event/EventDetails")
);
const NewsPage = React.lazy(() => import("views/admin/news"));
const WinnersTable = React.lazy(
  () => import("views/admin/events/winners/WinnersTable")
);
const Leaderboard = React.lazy(
  () => import("views/admin/events/leaderboard/Leaderboard")
);
const Invites = React.lazy(
  () => import("views/admin/events/event/invites/Invites")
);
const CMS = React.lazy(() => import("views/admin/CMS/CMS"));

const routes: RoutesType[] = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <HomeIcon />,
    component: <MainDashboard />,
    roles: ["admin", "manager", "company", "company_manager"],
    display: true,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: null,
    component: <Profile />,
    roles: ["admin", "manager", "company", "company_manager"],
    display: false,
  },
  {
    name: "News",
    layout: "/admin",
    path: "news",
    icon: <NewspaperIcon />,
    component: <NewsPage />,
    roles: ["admin", "manager"],
    display: true,
  },
  {
    name: "CMS",
    layout: "/admin",
    path: "cms",
    icon: <FileCopyIcon />,
    component: <CMS />,
    roles: ["admin", "manager"],
    display: true,
  },
  {
    name: "Registered Users",
    layout: "/admin",
    path: "users",
    icon: <PeopleAltIcon />,
    component: <Users />,
    roles: ["admin", "manager"],
    display: true,
  },
  {
    name: "Company Managers",
    layout: "/admin",
    path: "company/managers",
    icon: <ForumIcon />,
    component: <CompanyUsers />,
    roles: ["admin", "manager", "company"],
    display: true,
  },
  {
    name: "Contact Messages",
    layout: "/admin",
    path: "contact",
    icon: <MarkChatUnreadIcon />,
    component: <Contact />,
    roles: ["admin", "manager"],
    display: true,
  },
  {
    name: "Partner Companies",
    layout: "/admin",
    path: "companies",
    icon: <BusinessIcon />,
    component: <Companies />,
    roles: ["admin", "manager"],
    display: true,
  },
  {
    name: "Events",
    layout: "/admin",
    path: "events",
    icon: <TodayIcon />,
    component: <Events />,
    roles: ["admin", "manager", "company", "company_manager"],
    display: true,
  },
  {
    name: "Event Details",
    layout: "/admin",
    path: "events/:eventId/competitions", // This will match the event ID in the URL
    icon: "noicon",
    roles: ["admin", "manager", "company", "company_manager"],
    component: <EventDetails />, // Replace with the actual component for event details
  },
  {
    name: "QR Codes",
    layout: "/admin",
    path: "events/:eventId/competitions/:competitionId/qrs",
    icon: "noicon",
    roles: ["admin", "manager", "company", "company_manager"],
    component: <Invites />,
  },
  {
    name: "leaderboard",
    layout: "/admin",
    path: "events/:eventId/competitions/:competitionId/leaderboard",
    icon: "noicon",
    roles: ["admin", "manager", "company", "company_manager"],
    component: <Leaderboard />,
  },
  {
    name: "Winners",
    layout: "/admin",
    path: "events/:eventId/competitions/:competitionId/winners",
    icon: "noicon",
    roles: ["admin", "manager", "company", "company_manager"],
    component: <WinnersTable />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: null,
    component: <SignIn />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-up",
    icon: null,
    component: <SignUp />,
  },
];
export default routes;
