import React, { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "storage/AuthContext";

interface ProtectedRouteProps {
  children: ReactNode;
}

function ProtectAuthRoute({ children }: ProtectedRouteProps) {
  const { isLoggedIn } = useContext(AuthContext);
  if (isLoggedIn) return <Navigate to="/admin/default" />;

  return <>{children}</>;
}

export default ProtectAuthRoute;
