import { ReactNode } from "react";

function Card(props: {
  variant?: string;
  extra?: string;
  children?: JSX.Element | any[] | ReactNode;
  [x: string]: any;
}) {
  const { variant, extra, children, ...rest } = props;
  return (
    <div
      className={`relative flex flex-col gap-[12px] rounded-[16px] border-[1px] border-solid border-blue-200 bg-white bg-clip-border p-[24px] shadow-default dark:border-black-100 dark:!bg-black-400 dark:text-white dark:shadow-none ${extra}`}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Card;
