/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import Logo from "../../assets/svg/sidebar/logo.svg";
import LogoLight from "../../assets/svg/sidebar/logoLight.svg";

import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import routes from "routes";

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose } = props;
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 transition-all dark:border-[1px] dark:border-[#1F212A] dark:!bg-[#0D0E12] dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex items-center`}>
        <div className="ml-0 mt-1 h-2.5  dark:hidden">
          <img src={Logo} />
        </div>
        <div className="ml-0 mt-1 hidden h-2.5  text-navy-700 dark:block">
          <img src={LogoLight} />
        </div>
      </div>
      <div
        className="mb-7 mt-[58px] h-px bg-lightPrimary dark:bg-[#1F212A]
"
      />
      {/* Nav item */}

      <ul className="mb-auto flex flex-col items-start gap-2 self-stretch p-2 pl-4 pr-0">
        <Links routes={routes} />
      </ul>

      {/* Free Horizon Card */}
      {/* <div className="flex justify-center">
        <SidebarCard />
      </div> */}

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
