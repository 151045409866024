// Custom components
import React from "react";

function InputField(props: {
  id: string;
  label: string;
  extra?: string;
  placeholder: string;
  variant: string;
  state?: string;
  disabled?: boolean;
  type?: string;
  textArea?: boolean;
  value?: any;
  ref?: React.Ref<HTMLInputElement | null>;
  defaultValue?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeTextArea?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}) {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    disabled,
    textArea,
    value,
    defaultValue,
    onChange,
    onChangeTextArea,
  } = props;

  const inputField = textArea ? (
    <textarea
      disabled={disabled}
      id={id}
      placeholder={placeholder}
      autoComplete="current-password"
      className={`mt-2 flex h-[12vh] w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
        disabled === true
          ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
          : state === "error"
          ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
          : state === "success"
          ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
          : "border-blue-25 dark:!border-white/10 dark:text-white"
      }`}
      onChange={onChangeTextArea}
      value={value}
    />
  ) : (
    <input
      disabled={disabled}
      type={type}
      id={id}
      placeholder={placeholder}
      // defaultValue={defaultValue}
      autoComplete="current-password"
      className={` flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
        disabled === true
          ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
          : state === "error"
          ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
          : state === "success"
          ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
          : "border-blue-25 dark:border-black-100 dark:bg-black-400 dark:text-white"
      }`}
      onChange={onChange}
      value={value}
      onKeyDown={props.onKeyPress}
    />
  );

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm font-medium text-navy-700 dark:text-[#B5B7C8] ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      {inputField}
    </div>
  );
}

export default InputField;
