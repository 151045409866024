import { doc, updateDoc, getDoc } from "firebase/firestore";
import { firestore } from "./firebase";
import axios from "axios";

export const updateUserDataHelper = async (newData: any, uid: string) => {
  try {
    if (uid) {
      const userDocRef = doc(firestore, "users", uid);
      await updateDoc(userDocRef, newData);
      console.log("User data updated successfully");

      return { hasError: false };
    }
  } catch (error) {
    console.error("Error updating user data:", error);
    return { hasError: true, errorMessage: "something went wrong" };
  }
};

export const getUserDataHelper = async (uid: string) => {
  try {
    if (uid) {
      const userDocRef = doc(firestore, "users", uid);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        console.log("User data:", docSnap.data());
        return { hasError: false, data: docSnap.data() };
      } else {
        console.log("No such user!");
        return { hasError: true, errorMessage: "No such user" };
      }
    }
  } catch (error) {
    console.log(error);
    return { hasError: true, errorMessage: "something went wrong" };
  }
};

export const deleteUserHelper = async (id: string, token: string) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/user/delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res);
  } catch (err) {
    console.log(err);
  }
};
