import { Timestamp } from "firebase/firestore";
import DOMPurify from "dompurify";
import React from "react";

export function generateRandomPassword() {
  const characterSet =
    "abcdefghijklmnopqrsABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  let password = "";
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * characterSet.length);
    password += characterSet.charAt(randomIndex);
  }

  return password;
}

export function isValidEmail(email: string) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
}

export function createTimestamp(dateString: string) {
  const dateObject = new Date(dateString);
  const timestamp = Timestamp.fromDate(dateObject);
  return timestamp;
}

export function isValidNumber(str: string) {
  // Check if the string is not empty and starts with a non-zero digit
  if (str.length > 0 && str[0] !== "0" && /^\d+$/.test(str)) {
    return true; // Valid number
  }
  return false; // Invalid number
}

export function compareDates(startDate: string, endDate: string) {
  const sd = new Date(startDate);
  const ed = new Date(endDate);

  if (sd >= ed) {
    return false;
  }
  return true;
}

export function compareToCurrentDate(date: string) {
  const d = new Date(date);
  const cd = new Date();

  d.setHours(0, 0, 0, 0);
  cd.setHours(0, 0, 0, 0);
  if (d >= cd) {
    return false;
  }
  return true;
}

export function convertToISODate(dateString: string) {
  const [day, month, year] = dateString.split(/[/,:\s]+/);
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
}

export function isValidOTP(s: string): boolean {
  return /^\d{6}$/.test(s);
}

export function copyToClipboard(text: string) {
  if (navigator.clipboard) {
    // Use the modern Clipboard API
    navigator.clipboard
      .writeText(text)
      .then(() => console.log("Text copied to clipboard"))
      .catch((error) =>
        console.error("Failed to copy text to clipboard:", error)
      );
  } else {
    // Fallback for browsers that don't support the Clipboard API
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    console.log("Text copied to clipboard");
  }
}

export function formatStringForFieldValue(str: string) {
  // Convert the string to lowercase
  const lowerCaseStr = str.toLowerCase();

  // Replace all non-letter characters with underscores
  const formattedStr = lowerCaseStr.replace(/[^a-z]+/g, "_");

  return formattedStr;
}

function objectToArray(obj: { [key: string]: string }) {
  // Initialize an empty array to hold the results
  const result = [];

  // Iterate over each key-value pair in the input object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the key is a direct property of the object
      // Create a new object with the structure {key: key, value: obj[key]}
      // and add it to the result array
      result.push({ key: key, value: obj[key] });
    }
  }

  // Return the array of key-value pair objects
  return result;
}

export function searchObject(
  str: string,
  objArray: { [key: string]: string }[]
): { key: string; value: string }[] {
  // Make the string lowercase for case-insensitive comparison
  const lowerCaseStr = str.toLowerCase();

  // Initialize an array to hold all matching key-value pairs
  let results: { key: string; value: string }[] = [];

  // Search through the object array
  objArray.forEach((obj) => {
    for (const key in obj) {
      // Convert both key and value to lowercase for case-insensitive comparison
      if (
        key.toLowerCase().includes(lowerCaseStr) ||
        obj[key].toLowerCase().includes(lowerCaseStr)
      ) {
        // If a match is found, push an object with the original key and value to results
        results.push({ key: key, value: obj[key] });
      }
    }
  });

  return results;
}

export function searchArray(
  str: string,
  arrayArray: any[]
): { key: string; value: string }[] {
  // Make the string lowercase for case-insensitive comparison
  const lowerCaseStr = str.toLowerCase();
  const results = arrayArray.filter((item) => {
    let found = false;
    for (const key in item) {
      if (String(item[key]).toLowerCase().includes(lowerCaseStr)) {
        found = true;
        break;
      }
      for (const k in item[key]) {
        if (String(item[key][k]).toLowerCase().includes(lowerCaseStr)) {
          found = true;
          break;
        }
      }
    }
    return found;
  });

  return results;
}

export function transformTranslations(translationArray: any[]) {
  // Initialize an empty array to hold the transformed objects
  const result = [] as any[];

  // Iterate through the array of translation objects
  translationArray.forEach((translationObj) => {
    const language = translationObj.id; // Extract the language code

    // Iterate through the keys of each translation object except the 'id' key
    Object.keys(translationObj).forEach((key) => {
      if (key !== "id") {
        // Skip the 'id' key as it's used for the language code
        let entry = result.find((item) => item.key === key);
        if (entry) {
          entry.values[language] = translationObj[key];
        } else {
          result.push({
            key: key,
            values: { [language]: translationObj[key] },
          });
        }
      }
    });
  });

  return result;
}

export function extractTextFromHtml(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return DOMPurify.sanitize(doc.body.textContent.trim());
}

export const preloadableComponent = (
  importFunction: () => Promise<{ default: React.ComponentType<any> }>
) => {
  const Component = React.lazy(importFunction);
  const preload = () => importFunction();

  return [Component, preload] as const;
};
