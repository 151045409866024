import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <h2 className="mb-4 text-3xl font-semibold">Oops! Page Not Found</h2>
      <p className="mb-4 text-lg">
        The route you are trying to access doesn't exist.
      </p>
      <Link to="/admin" className="text-blue-500 hover:underline">
        Go to Main Page
      </Link>
    </div>
  );
}

export default NotFound;
