import { useEffect, useRef, ReactNode } from "react";
import { createPortal } from "react-dom";

interface PortalProps {
  children: ReactNode;
}

const Portal: React.FC<PortalProps> = ({ children }) => {
  const el = useRef(document.createElement("div")).current; // Only created once
  const mount = document.getElementById("portal");

  useEffect(() => {
    if (mount) {
      mount.appendChild(el);
    }
    return () => {
      if (mount) {
        mount.removeChild(el);
      }
    };
  }, [mount, el]); // el is stable, so it's okay to include it here

  return createPortal(children, el);
};

export default Portal;
