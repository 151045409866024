import React, { ReactNode, useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { signOutHelper } from "../../utils/auth.helper";
import AuthContext from "storage/AuthContext";

interface ProtectedRouteProps {
  children: ReactNode;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { isLoggedIn } = useContext(AuthContext);

  if (!isLoggedIn) return <Navigate to="/auth/sign-in" />;

  return <>{children}</>;
}

export default ProtectedRoute;
