/* eslint-disable */
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import AuthContext from "storage/AuthContext";
import Active from "../../../assets/svg/sidebar/Active.svg";
// chakra imports

export const SidebarLinks = (props: { routes: RoutesType[] }): JSX.Element => {
  // Chakra color mode
  let location = useLocation();

  const { currentUser } = useContext(AuthContext);
  const { role } = currentUser;

  const { routes } = props;

  const correctedRoutes = routes.filter((route) => {
    if (route.roles) {
      return route.roles.includes(role);
    } else return false;
  });

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes: RoutesType[]) => {
    const visibleItems = routes.filter((route) => route.display);
    return visibleItems.map((route, index) => {
      if (
        route.layout === "/admin"
        // route.layout === "/auth" ||
        // route.layout === "/rtl"
      ) {
        if (route.icon === "noicon") return;
        return (
          <Link key={index} to={route.layout + "/" + route.path}>
            <div className=" relative flex hover:cursor-pointer">
              <li
                className={`flex w-60 cursor-pointer items-center gap-[32px]  rounded-xl px-0 py-2.5 transition-all`}
                key={index}
              >
                <span
                  className={`h-[24px] w-[24px] ${
                    activeRoute(route.path) === true
                      ? "font-bold text-blue-700 "
                      : "font-medium text-textLight dark:text-[#464852] "
                  }`}
                >
                  {route.icon ? <>{route.icon}</> : <DashIcon />}
                </span>
                <p
                  className={`leading-1 flex ${
                    activeRoute(route.path) === true
                      ? "font-[500] text-blue-800 dark:text-white"
                      : " font-[500] text-textLight dark:text-textDarkLight"
                  }`}
                >
                  {route.name}
                </p>
              </li>

              {activeRoute(route.path) ? (
                <div className="right-4">
                  <img src={Active} />
                </div>
              ) : null}
            </div>
          </Link>
        );
      }
    });
  };

  //  const createLinks = (routes: RoutesType[]) => {
  //    const visibleItems = routes.filter((route) => route.display);
  //    return visibleItems.map((route, index) => {
  //      if (
  //        route.layout === "/admin"
  //        // route.layout === "/auth" ||
  //        // route.layout === "/rtl"
  //      ) {
  //        if (route.icon === "noicon") return;
  //        return (
  //          <Link key={index} to={route.layout + "/" + route.path}>
  //            <div className="relative mb-3 flex hover:cursor-pointer">
  //              <li
  //                className="my-[3px] flex cursor-pointer items-center px-8"
  //                key={index}
  //              >
  //                <span
  //                  className={`${
  //                    activeRoute(route.path) === true
  //                      ? "font-bold text-brand-550 dark:text-white"
  //                      : "font-medium text-gray-600"
  //                  }`}
  //                >
  //                  {route.icon ? <>{route.icon}</> : <DashIcon />}
  //                </span>
  //                <p
  //                  className={`leading-1 ml-4 flex ${
  //                    activeRoute(route.path) === true
  //                      ? "font-bold text-navy-700 dark:text-white"
  //                      : "font-medium text-gray-600"
  //                  }`}
  //                >
  //                  {route.name}
  //                </p>
  //              </li>
  //              {activeRoute(route.path) ? (
  //                <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
  //              ) : null}
  //            </div>
  //          </Link>
  //        );
  //      }
  //    });
  //  };
  // BRAND
  return <>{createLinks(correctedRoutes)}</>;
};

export default SidebarLinks;
