import React, { useState } from "react";
import { auth } from "../../utils/firebase";
import {
  createUserWithEmailAndPassword,
  //   sendEmailVerification,
} from "firebase/auth";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignUp = async () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // User created successfully, now send verification email.
        // sendEmailVerification(userCredential.user)
        //   .then(() => {
        //     console.log("Verification email sent!");
        //   })
        //   .catch((error) => {
        //     console.error("Error sending verification email:", error);
        //   });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("Error creating user:", errorCode, errorMessage);
      });
  };

  return (
    <div>
      <h2>Sign Up</h2>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleSignUp}>Sign Up</button>
    </div>
  );
};

export default SignUp;
