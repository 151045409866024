import React, { useEffect, useState } from "react";
// import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import Modal from "components/modal/layout/Modal";
import Loader from "../animations/Loader";
import { auth } from "../../utils/firebase";
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import PasswordInputField from "components/fields/PasswordInputField";

interface PasswordUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  disableClose?: boolean;
}

function PasswordUpdateModal({
  isOpen,
  onClose,
  disableClose,
}: PasswordUpdateModalProps) {
  const [oldPassowrd, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const oldPasswordChangeHandler = (e: any) => {
    setOldPassword(e.target.value);
  };
  const passwordChangeHandler = (e: any) => {
    setPassword(e.target.value);
  };
  const passwordConfirmChagneHandler = (e: any) => {
    setPasswordConfirm(e.target.value);
  };

  useEffect(() => {
    if (password && passwordConfirm && oldPassowrd) setErrorMessage("");
    return () => {
      setErrorMessage("");
    };
  }, [password, passwordConfirm, oldPassowrd]);

  const submitHandler = async (event: any) => {
    event.preventDefault();
    //check for input validity
    if (!password || !passwordConfirm || !oldPassowrd) {
      setErrorMessage("You should fill out all fields");
      return;
    }
    if (password !== passwordConfirm) {
      setErrorMessage("passwords do not match");
      return;
    }

    setIsLoading(true);

    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      oldPassowrd
    );
    try {
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, password);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage("please make password is correct");
      return;
    }

    setIsLoading(false);

    onClose();
  };
  /*
  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay className="z-100 bg-[#000] !opacity-30" />
      <ModalContent className="top-[25vh] !z-[1002] !m-auto !w-max w-[100%] min-w-[350px] !max-w-[85%] md:top-[14vh]">
        <ModalBody className="z-100">
          <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
            <h1 className="mb-[40px] text-2xl font-bold">Update Password</h1>
            {errorMessage && <p className=" text-red-500">{errorMessage}</p>}
            <form>
              <PasswordInputField
                variant="auth"
                extra="mb-3"
                label="old password"
                placeholder="old password"
                id="oldpassword"
                type="password"
                onChange={oldPasswordChangeHandler}
              />
              <PasswordInputField
                variant="auth"
                extra="mb-3"
                label="password"
                placeholder="password"
                id="password"
                type="password"
                onChange={passwordChangeHandler}
              />
              <PasswordInputField
                variant="auth"
                extra="mb-3"
                label="passwordConfirm"
                placeholder="password confirm"
                id="passwordConfirm"
                type="password"
                onChange={passwordConfirmChagneHandler}
              />

              <div className="mt-[40px] flex gap-2">
                {!disableClose && (
                  <button
                    onClick={onClose}
                    className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                  >
                    Close
                  </button>
                )}
                <button
                  onClick={submitHandler}
                  className="linear w-[100%] cursor-pointer rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-400 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  {isLoading ? <Loader size={0.7} /> : "Submit"}
                </button>
              </div>
            </form>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
  */
  return (
    <Modal closeModal={() => {}}>
      <h1 className="mb-[40px] text-2xl font-bold">Update Password</h1>
      {errorMessage && <p className=" text-red-500">{errorMessage}</p>}
      <form>
        <PasswordInputField
          variant="auth"
          extra="mb-3"
          label="old password"
          placeholder="old password"
          id="oldpassword"
          type="password"
          onChange={oldPasswordChangeHandler}
        />
        <PasswordInputField
          variant="auth"
          extra="mb-3"
          label="password"
          placeholder="password"
          id="password"
          type="password"
          onChange={passwordChangeHandler}
        />
        <PasswordInputField
          variant="auth"
          extra="mb-3"
          label="passwordConfirm"
          placeholder="password confirm"
          id="passwordConfirm"
          type="password"
          onChange={passwordConfirmChagneHandler}
        />

        <div className="mt-[40px] flex gap-2">
          {!disableClose && (
            <button
              onClick={onClose}
              className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
            >
              Close
            </button>
          )}
          <button
            onClick={submitHandler}
            className="linear w-[100%] cursor-pointer rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-400 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
          >
            {isLoading ? <Loader size={0.7} /> : "Submit"}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default PasswordUpdateModal;
