import { useEffect, useState } from "react";
import Modal from "./layout/Modal";
import InputField from "components/fields/InputField";
import Loader from "../animations/Loader";
import { isValidEmail } from "../../utils/functions.helper";
import { resetPassword } from "utils/auth.helper";

interface PasswordResetModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function PasswordResetModal({ isOpen, onClose }: PasswordResetModalProps) {
  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (email) setErrorMessage("");
    return () => {
      setErrorMessage("");
    };
  }, [email]);

  const submitHandler = async (event: any) => {
    event.preventDefault();
    //check for input validity
    if (!email) {
      setErrorMessage("You should fill out all fields");
      return;
    }
    if (!isValidEmail(email)) {
      setErrorMessage("Please enter a valid email");
      return;
    }

    //get data

    setIsLoading(true);

    try {
      setIsLoading(false);
      const res = await resetPassword(email);
    } catch (err) {
      setErrorMessage("something went wrong");
      setIsLoading(false);
      return;
    }

    //send request to server
  };

  const copyHandler = () => {
    //copy logic
    onClose();
    setEmail("");
  };
  return (
    <Modal closeModal={() => {}}>
      <h1 className="mb-[40px] text-2xl font-bold">Forgot Password?</h1>
      {errorMessage && <p className=" text-red-500">{errorMessage}</p>}
      <form>
        <InputField
          variant="auth"
          extra="mb-3"
          label="email"
          placeholder="email"
          id="email"
          type="text"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <div className="mt-[40px] flex gap-2">
          <button
            onClick={onClose}
            className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
          >
            Close
          </button>
          <button
            onClick={submitHandler}
            className="linear w-[100%] cursor-pointer rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-400 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
          >
            {isLoading ? <Loader size={0.7} /> : "Send"}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default PasswordResetModal;
