import { useState } from "react";

import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";

import {
  signInWithGoogleHelper,
  signInWithEmailAndPasswordHelper,
} from "utils/auth.helper";
import Loader from "components/animations/Loader";
import { useDisclosure } from "@chakra-ui/hooks";
import PasswordResetModal from "components/modal/PasswordResetModal";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const loginHandler = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await signInWithEmailAndPasswordHelper(email, password);
    } catch (err) {
      setErrorMessage("something went wrong");
    }

    setIsLoading(false);
  };

  const googleLoginHandler = async () => {
    setIsLoading(true);
    try {
      await signInWithGoogleHelper();
    } catch (err) {
      setErrorMessage("something went wrong");
    }

    setIsLoading(false);
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">
      {/* Sign in section */}
      <div className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <div
          className="mb-6 flex h-[50px] w-full cursor-pointer items-center justify-center gap-2 rounded-xl bg-lightGrey hover-opacity-80 dark:bg-[#1B1C22]"
          onClick={googleLoginHandler}
        >
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>

        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-black-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-black-700" />
        </div>
        <form>
          {/* Email */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@simmmple.com"
            id="email"
            type="text"
            onChange={(e) => setEmail(e.target.value)}
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />

          {/* Checkbox */}
          <div className="mb-4 flex items-center justify-between px-2">
            {/* <div className="flex items-center">
              <Checkbox />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                Keep me logged In
              </p>
            </div> */}
            <p
              className="cursor-pointer text-sm font-medium text-brand-600 hover-opacity-80 dark:text-white"
              onClick={onOpen}
            >
              Forgot Password?
            </p>
          </div>
          <button
            onClick={loginHandler}
            className="linear mt-2 w-full rounded-xl bg-brand-600 py-[12px] text-base font-medium text-white hover-opacity-80 active:bg-brand-700 "
          >
            {isLoading ? <Loader size={1.5} /> : "Sign In"}
          </button>
        </form>

        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
            Sign up
          </a>
        </div> */}

        <div className="mt-4">
          {errorMessage && (
            <p className="text-lg text-red-700">{errorMessage}</p>
          )}
        </div>
      </div>
      {isOpen && <PasswordResetModal isOpen={isOpen} onClose={onClose} />}
    </div>
  );
}
