import React from "react";
import { motion } from "framer-motion";

//styles

const textStyle = {
  fontSize: "24px",
  color: "rgb(191 192 193)",
};

const spinTransition = {
  repeat: Infinity,
  ease: "linear",
  duration: 2,
};

export default function CircleLoader(props: { size?: number }) {
  const { size } = props;
  //spinner loader, contains the whole screen (accsess with /loader link)
  const circleStyle = {
    display: "block",
    width: size ? `${size}rem` : "3rem",
    height: size ? `${size}rem` : "3rem",
    border: size ? `${size / 4}rem solid #D3E3FD` : "0.5rem solid #D3E3FD",
    borderTop: size ? `${size / 4}rem solid #0B57D0` : "0.5rem solid #0B57D0",
    borderRadius: "50%",
    color: "#0B57D0",
  };

  return (
    <div
      className={`${
        size ? "flex justify-center" : "bg-black m-[40px] flex justify-center"
      }`}
    >
      {/* <p style={textStyle}>Loading...</p> */}
      <motion.span
        style={circleStyle}
        animate={{ rotate: 360 }}
        transition={spinTransition}
      />
    </div>
  );
}
