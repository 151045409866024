import { ChangeEvent } from "react";
import { storage } from "./firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
  deleteObject,
} from "firebase/storage";

export const handleUpload = (
  event: ChangeEvent<HTMLInputElement>,
  path: string,
  filename: string
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const file = event.target.files[0];
    if (!file) {
      reject("No file selected");
      return;
    }
    console.log("filename: ", filename);
    const storageRef = ref(storage, `uploads/${path}${filename}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle errors
        console.log("Upload error:", error);
        reject(error);
      },
      () => {
        // Handle successful uploads & get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};

export const fethImageUrl = async (imagePath: string) => {
  const imageRef = ref(storage, imagePath);

  try {
    const url = await getDownloadURL(imageRef);

    return url;
  } catch (error) {
    console.error("Error fetching image URL:", error);
  }
};

export const deleteStorageObject = async (path: string): Promise<void> => {
  try {
    const storage = getStorage();
    const storageRef = ref(storage, path);

    await deleteObject(storageRef);
    console.log("File successfully deleted");
  } catch (error) {
    console.error("Error deleting file:", error);
    throw error; // Re-throw the error for handling it outside
  }
};
