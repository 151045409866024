import { Suspense, useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import { useDisclosure } from "@chakra-ui/hooks";

import AuthContext from "storage/AuthContext";

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";

import ProtectedRoute from "components/protectedRoute/ProtectedRoute";
import ProtectAuthRoute from "components/protectedRoute/ProtectAuthRoute";

import PasswordUpdateModal from "components/modal/PasswordUpdateModal";
import CompanyInfoUpdateModal from "components/modal/CompanyInfoUpdateModal";
import NotFound from "views/notfound/NotFound";
import Loader from "components/animations/Loader";

//React

//npms

//context
//hooks
//utility functions

// routes //layouts // components // modals //animations

//types

//icons

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const { forcePasswordReset } = useContext(AuthContext);
  const {
    isOpen: isOpenPasswordResetModal,
    onOpen: onOpenPasswordResetModal,
    onClose: onClosePasswordResetModal,
  } = useDisclosure();

  const {
    isOpen: isOpenCompanyInfoModal,
    onOpen: onOpenCompanyInfoModal,
    onClose: onCloseCompanyInfoModal,
  } = useDisclosure();

  useEffect(() => {
    if (forcePasswordReset) {
      onOpenPasswordResetModal();
    }
  }, [forcePasswordReset, onOpenPasswordResetModal]);

  const openCompanyInfoAfterPasswordReset = () => {
    onOpenCompanyInfoModal();
  };
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        <Routes>
          <Route
            path="auth/*"
            element={
              <ProtectAuthRoute>
                <AuthLayout />
              </ProtectAuthRoute>
            }
          />
          <Route
            path="admin/*"
            element={
              <ProtectedRoute>
                <AdminLayout />
              </ProtectedRoute>
            }
          />
          <Route
            path={"*"}
            element={
              <ProtectedRoute>
                {/* Component for handling all other routes */}
                <NotFound />
              </ProtectedRoute>
            }
          />
          <Route path="/" element={<Navigate to="/admin" replace />} />
        </Routes>
      </Suspense>
      {isOpenCompanyInfoModal && (
        <CompanyInfoUpdateModal
          isOpen={isOpenCompanyInfoModal}
          disableClose={true}
          onClose={onCloseCompanyInfoModal}
        />
      )}
      {isOpenPasswordResetModal && (
        <PasswordUpdateModal
          isOpen={isOpenPasswordResetModal}
          onClose={() => {
            onClosePasswordResetModal();
            openCompanyInfoAfterPasswordReset();
          }}
          disableClose={true}
        />
      )}
    </QueryClientProvider>
  );
};

export default App;
