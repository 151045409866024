import React, { useEffect, useState, useContext } from "react";
import Modal from "./layout/Modal";
import Card from "components/card";
import InputField from "components/fields/InputField";
import Loader from "../animations/Loader";
import { updateUserDataHelper } from "utils/users.helper";
import AuthContext from "storage/AuthContext";

interface PasswordUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  disableClose?: boolean;
}

function CompanyInfoUpdateModal({
  isOpen,
  onClose,
  disableClose,
}: PasswordUpdateModalProps) {
  const { currentUser } = useContext(AuthContext);
  const [companyName, setCompanyName] = useState(currentUser.companyName || "");
  const [firstname, setfirstname] = useState(currentUser.firstname || "");
  const [lastname, setlastname] = useState(currentUser.lastname || "");
  const [number, setNumber] = useState(currentUser.number || "");

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const role = currentUser?.role;
  const isCompany = role === "company";

  const firstnameChangeHandler = (e: any) => {
    setfirstname(e.target.value);
  };
  const lastnameChangeHandler = (e: any) => {
    setlastname(e.target.value);
  };
  const numberChangeHandler = (e: any) => {
    setNumber(e.target.value);
  };
  const companyNameChangeHandler = (e: any) => {
    setCompanyName(e.target.value);
  };

  useEffect(() => {
    if (firstname && number && lastname) setErrorMessage("");
    return () => {
      setErrorMessage("");
    };
  }, [firstname, number, lastname]);

  const submitHandler = async (event: any) => {
    event.preventDefault();
    //check for input validity
    if (!firstname || !number || !lastname) {
      setErrorMessage("You should fill out all fields");
      return;
    }
    if (!companyName && isCompany) {
      setErrorMessage("You shoudl fill out all fields");
      return;
    }

    //get data
    const data = {
      firstname: firstname,
      lastname: lastname,
      companyName: companyName,
      number: number,
      forcePasswordReset: false,
    };

    setIsLoading(true);

    //send a request

    const res = await updateUserDataHelper(data, currentUser.uid);
    if (res.hasError) {
      setErrorMessage(res.errorMessage);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    onClose();
  };

  return (
    <Modal closeModal={() => {}}>
      <h1 className="mb-[40px] text-2xl font-bold">Update Info</h1>
      {errorMessage && <p className=" text-red-500">{errorMessage}</p>}
      <form>
        {isCompany && (
          <InputField
            variant="auth"
            extra="mb-3"
            label="company name"
            placeholder="company name"
            id="companyName"
            type="text"
            value={companyName}
            onChange={companyNameChangeHandler}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 12,
          }}
        >
          <InputField
            variant="auth"
            extra="mb-3 w-1/2"
            label="first name"
            placeholder="first name"
            id="firstname"
            type="text"
            value={firstname}
            onChange={firstnameChangeHandler}
          />
          <InputField
            variant="auth"
            extra="mb-3 w-1/2"
            label="last name"
            placeholder="last name"
            id="lastname"
            type="text"
            value={lastname}
            onChange={lastnameChangeHandler}
          />
        </div>

        <InputField
          variant="auth"
          extra="mb-3"
          label="number"
          placeholder="number"
          id="number"
          type="text"
          value={number}
          onChange={numberChangeHandler}
        />

        <div className="mt-[40px] flex gap-2">
          {!disableClose && (
            <button
              onClick={onClose}
              className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
            >
              Close
            </button>
          )}
          <button
            onClick={submitHandler}
            className="linear w-[100%] cursor-pointer rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-400 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
          >
            {isLoading ? <Loader size={0.7} /> : "Save"}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default CompanyInfoUpdateModal;
