import React, { useEffect } from "react";
import Portal from "./Portal";
import Card from "components/card";

interface ModalProps {
  children: React.ReactNode;
  closeModal: () => void;
}

const Modal: React.FC<ModalProps> = ({ children, closeModal }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <Portal>
      <div
        className="fixed inset-0 z-40 bg-[#00000030] bg-opacity-50"
        onClick={closeModal}
      ></div>
      <div className="fixed left-1/2 top-1/2 z-50 flex -translate-x-1/2 -translate-y-1/2 transform items-center justify-center">
        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004] xxs:w-[320px] xs:w-[450px]">
          {children}
        </Card>
      </div>
    </Portal>
  );
};

export default Modal;
